export const environment = {
	production: false,
	config: {
		apiKey: 'AIzaSyAuRC2Ew6AzePFArgE8phfdmSQStiam7dE',
		authDomain: 'blackmenheal-nprd.firebaseapp.com',
		projectId: 'blackmenheal-nprd',
		storageBucket: 'blackmenheal-nprd.appspot.com',
		messagingSenderId: '756012963917',
		appId: '1:756012963917:web:1d3e49476bf34fb148e404',
	},
	API_URL: 'https://blackmenheal-nprd.uk.r.appspot.com',
	storage_bucket:
		'https://storage.googleapis.com/blackmenheal-nprd.appspot.com',
	bucket_name: 'blackmenheal-nprd.appspot.com',
	docs_bucket: 'https://storage.googleapis.com/blackmenheal-nprd-docs',
	docs_bucket_name: 'blackmenheal-nprd-docs',
};
